import {React, useState, useEffect} from 'react';
import Divider from './individual/Divider';
import Spacer from './individual/Spacer';
import Table from './individual/Table';
import Title from './individual/Title';

export default function Dozen() {
    const [cartonClient, setCartonClient] = useState([])
    const [cartonSchool, setCartonSchool] = useState([])

    useEffect(() => {
        const getCartonClient = async() => {
            const res = await fetch('https://api.2024.leonidas.optimize-it.be/carton/list/client/', 
            { 
              headers: {'Authorization': 'Bearer ' + localStorage.getItem('accesstoken')}
            }
          )
            const data = await res.json()
            setCartonClient(data)
        }
        const getCartonSchool = async() => {
            const res = await fetch('https://api.2024.leonidas.optimize-it.be/carton/list/school/', 
            { 
              headers: {'Authorization': 'Bearer ' + localStorage.getItem('accesstoken')}
            }
          )
            const data = await res.json()
            setCartonSchool(data)
      }
        getCartonClient();
        getCartonSchool();
    }, [])

  return ( 
    <div className="mx-24">
      <Spacer height={50} />
      <Title text="Alle kartons (klanten)" type="head" />
      <Spacer height={15} />
      <Divider />
      <Spacer height={50} />
      <Table data={cartonClient} fields={['Kartonnaam', 'Klantnaam']} edit={false} />
      <Spacer height={50} />
      <Title text="Alle kartons (scholen)" type="head" />
      <Spacer height={15} />
      <Divider />
      <Spacer height={50} />
      <Table data={cartonSchool} fields={['Kartonnaam', 'Klas', 'School']} edit={false} />
      <Spacer height={50} />
    </div>
)};